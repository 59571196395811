<template>
  <BaseModal
    name="modal-edit-pixel"
    id="modal-edit-pixel"
    idModal="modal-edit-pixel"
    size="lg"
    title="Editar Pixel"
    @shown="editPixel"
  >
    <div class="container-grid" v-if="!loading">
      <!-- Tipo -->

      <div id="select-type-pixel-edit">
        <label for="pixel_type">Selecione o tipo do pixel</label>
        <multiselect
          v-model="selected_type_pixel"
          id="pixel_type"
          label="name"
          track-by="id"
          style="
            background-color: transparent !important;
            opacity: 1 !important;
            margin-bottom: 12px !important;
          "
          placeholder="Pesquise por um pixel"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="bottom"
          :options="types"
          :multiple="false"
          :taggable="false"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="false"
          disabled
        >
          <span slot="noOptions">Pesquise por um pixel</span>
          <span slot="noResult">Oops! Nenhum pixel encontrado.</span>
        </multiselect>
      </div>
      <!-- Titulo -->
      <div>
        <label for="pixel_title">Titulo</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_title"
          v-model="title"
        />
      </div>
      <!-- Pixel ID -->
      <div>
        <label for="pixel_id">Pixel ID</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_id"
          v-model="pixel_id"
          disabled
        />
      </div>

      <!-- <div v-if="selected_type_pixel.type == 'GOOGLEADWORDS'">
        <label for="pixel_id">Label de conversão (opcional)</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_id"
          v-model="conversion_label"
        />
      </div> -->
      <div>
        <!-- Teste pixel -->
        <div v-if="selected_type_pixel.type == 'FACEBOOK'">
          <label for="pixel_test">Teste pixel</label>
          <input
            style="width: 100%"
            type="text"
            id="pixel_test"
            v-model="pixel_test"
          />

          <small
            >No final da url da página adicione
            <strong data-v-18b59dfe="">?test_event=teste</strong>, para que o
            facebook possa localizar o evento cadastrado.
          </small>
        </div>
        <!-- Selects -->
        <div>
          <!-- Mensagem -->

          <!-- Visualização -->
          <b-form-checkbox
            style="margin-top: 12px"
            id="checkbox-Visualização"
            name="checkbox-1"
            v-model="visualizacao"
          >
            Eventos de visualização
          </b-form-checkbox>
          <!-- Conversão -->
          <b-form-checkbox
            id="checkbox-Conversão"
            name="checkbox-1"
            v-model="conversao"
          >
            Eventos de conversão
          </b-form-checkbox>
          <div v-if="selected_type_pixel.type == 'FACEBOOK'">
            <!-- Navegador -->
            <b-form-checkbox
              id="checkbox-Navegador"
              name="checkbox-1"
              v-model="navegar"
            >
              <div style="display: grid">
                Navegador
                <small>Dados serão salvos direto do navegador do usuário</small>
              </div>
            </b-form-checkbox>
            <!-- Conversão API -->
            <b-form-checkbox
              id="checkbox-api"
              name="checkbox-1"
              v-model="api_conversao"
            >
              <div style="display: grid">
                Api de conversão
                <small> Dados serão enviados para a API de conversão </small>
              </div>
            </b-form-checkbox>
            <!-- Token -->
            <div v-if="api_conversao">
              <label for="token_api">Token api de conversão</label>
              <input
                style="width: 100%"
                type="text"
                name="token_api"
                id="token_api"
                v-model="token_api"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SE EXISTIR LOADING-->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit">
        Salvar alterações
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import PixelService from "@/services/resources/PixelService";
const servicePixel = PixelService.build();
//
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      id: null,
      token_api: "",
      api_conversao: false,
      navegar: false,
      conversao: false,
      visualizacao: true,
      pixel_test: "",
      pixel_id: "",
      title: "",
      selected_type_pixel: {},
      types: [
        { id: 1, name: "Facebook", type: "FACEBOOK" },
        { id: 2, name: "Google Ads", type: "GOOGLEADWORDS" },
        { id: 3, name: "Google Tag Manager", type: "GOOGLETAGMANAGER" },
        { id: 4, name: "Google Analytics", type: "GOOGLEANALYTICS" },
        { id: 5, name: "Tiktok", type: "TIKTOK" },
      ],
      loading: false,
      conversion_label: "",
    };
  },

  props: ["pixel"],
  watch: {},
  mounted() {},

  methods: {
    editPixel() {
      this.selected_type_pixel = this.types.find(
        (x) => x.type == this.pixel.type
      );
      this.title = this.pixel.title;
      this.pixel_id = this.pixel.pixel_id;
      this.pixel_test = this.pixel.test_event_code_pixel;
      this.visualizacao = this.pixel.view == "1" ? true : false;
      this.conversao = this.pixel.conversion == "1" ? true : false;
      this.navegar = this.pixel.web == "1" ? true : false;
      this.api_conversao = this.pixel.api == "1" ? true : false;
      this.token_api = this.pixel.token;
      this.id = this.pixel.id;
      this.conversion_label = this.pixel.conversion_label;
    },
    onSubmit() {
      this.loading = true;

      var data = {
        pixel_id: this.pixel_id,
        type: this.selected_type_pixel.type,
        title: this.title,
        view: this.visualizacao ? "1" : "0",
        conversion: this.conversao ? "1" : "0",
        web: this.navegar ? "1" : "0",
        api: this.api_conversao ? "1" : "0",
        token: this.token_api,
        test_event_code_pixel: this.pixel_test,
        id: this.id,
        conversion_label: this.conversion_label,
      };
      servicePixel
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Pixel editado com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          (this.token_api = ""),
            (this.api_conversao = false),
            (this.navegar = false),
            (this.conversao = false),
            (this.visualizacao = true),
            (this.pixel_test = ""),
            (this.pixel_id = ""),
            (this.title = ""),
            this.$parent.getPixels();
          this.$bvModal.hide("modal-edit-pixel");
        })
        .catch((error) => {
          // console.log(error);
          this.$bvToast.toast("Erro ao editar pixel", {
            title: "Pixel",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped langue="scss">
.container-grid {
  display: grid;
  gap: 5px;
}
</style>
