<template>
  <div class="container">
    <BaseHeader title="Pixel" :navigation="navigation"></BaseHeader>
    <div class="box-total" v-if="!loading">
      <div class="total-vendas info">
        <!-- <b-form-checkbox
            v-model="status"
            @change="changeStatusForm"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativos " : "Inativos" }}
            </p>
          </b-form-checkbox> -->
      </div>
      <div>
        <p class="total-vendas">
          Total de pixel:
          <span>{{ total }}</span>
        </p>
      </div>
      <div></div>
    </div>

    <div v-else>
      <b-skeleton
        width="100%"
        height="83px"
        style="border-radius: 10px"
      ></b-skeleton>
    </div>
    <div class="header-table" style="justify-content: end; gap: 15px">
      <BaseButton
        variant="primary"
        @click="openModal('create-new-pixel')"
        v-if="!loading && !isMobile"
      >
        Novo Pixel
      </BaseButton>
      <b-skeleton
        v-if="loading && !isMobile"
        width="135px"
        height="50px"
        type="button"
        style="border-radius: 10px"
      ></b-skeleton>
    </div>
    <!-- LISTAGEM DE PIXELS-->
    <!-- v-if="!loading && pixels && pixels.length > 0" -->
    <div class="mt-5">
      <b-row
        class="Table-header mb-1"
        style="justify-content: space-between; display: flex"
      >
        <!--Pixel ID-->
        <b-col cols="2" class="d-none d-md-block" style="padding-left: 20px">
          Pixel ID
        </b-col>

        <!--Tipo-->
        <b-col cols="2" class="d-none d-md-block" style="padding-left: 15px">
          Tipo
        </b-col>

        <!--Titulo-->
        <b-col cols="2" class="d-none d-md-block" style="padding-left: 10px">
          Título
        </b-col>

        <!--Visualização-->
        <b-col cols="1" class="d-none d-md-block" style="padding-left: 0px">
          Visualização
        </b-col>

        <!--Conversão-->
        <b-col cols="1" class="d-none d-md-block" style="padding-left: 0px">
          Conversão
        </b-col>

        <!--Envios Web-->
        <b-col
          cols="1"
          class="d-none d-md-block"
          style="padding-left: 0px; white-space: nowrap"
        >
          Envios Web
        </b-col>

        <!--Api de conversão-->
        <b-col
          cols="1"
          class="d-none d-md-block"
          style="padding-left: 0px; white-space: nowrap"
        >
          Api de conversão
        </b-col>

        <!--Ações-->
        <b-col
          cols="1"
          class="d-none d-md-block"
          style="
            padding-left: 20px;
            display: flex !important;
            justify-content: flex-end !important;
          "
          >Ações</b-col
        >
      </b-row>
      <template v-if="!loading">
        <div
          v-for="(pixel, index) in pixels"
          :key="index"
          class="Table-body-container"
        >
          <template v-if="!isMobile">
            <b-row
              class="Table-body"
              id="border-bottom"
              style="justify-content: space-between"
            >
              <!--Pixel ID-->
              <b-col cols="2">
                {{ pixel.pixel_id }}
              </b-col>
              <!--Tipo-->
              <b-col cols="2">
                <img
                  v-b-tooltip="{ title: 'Facebook', placement: 'top' }"
                  src="../../assets/icons/trafego/iconFacebook.svg"
                  alt="Facebook"
                  v-if="pixel.type === 'FACEBOOK'"
                />
                <img
                  v-b-tooltip="{ title: 'Google Ads', placement: 'top' }"
                  src="../../assets/icons/trafego/iconGoogleAds.svg"
                  alt="GoogleAds"
                  v-else-if="pixel.type === 'GOOGLEADWORDS'"
                />
                <img
                  v-b-tooltip="{ title: 'Tiktok', placement: 'top' }"
                  src="../../assets/icons/trafego/iconTiktok.svg"
                  alt="Google Ads"
                  v-else-if="pixel.type === 'TIKTOK'"
                />
                <img
                  v-b-tooltip="{ title: 'Google Analytics', placement: 'top' }"
                  src="../../assets/icons/iconGoogleAnalytics.svg"
                  alt="Google Analytics"
                  v-else-if="pixel.type === 'GOOGLEANALYTICS'"
                />
                <img
                  v-b-tooltip="{ title: 'Google Menager', placement: 'top' }"
                  src="../../assets/icons/iconGoogleMenager.svg"
                  alt="Google Menager"
                  v-else-if="pixel.type === 'GOOGLETAGMANAGER'"
                />
              </b-col>
              <!--Titulo-->
              <b-col
                cols="2"
                v-if="pixel && pixel.title.length > 20"
                v-b-tooltip.hover
                :title="pixel.title"
              >
                {{
                  pixel.title && pixel.title.length > 20
                    ? pixel.title.substr(0, 20) + "..."
                    : pixel.title
                }}
              </b-col>
              <b-col cols="2" v-else>
                {{ pixel.title }}
              </b-col>
              <!--Visualização-->
              <b-col
                cols="1"
                :class="{ inactive: !pixel.view, active: pixel.view }"
              >
                {{ pixel.view ? "Ativo" : "Inativo" }}
              </b-col>
              <!--Conversão-->
              <b-col
                cols="1"
                :class="{
                  inactive: !pixel.conversion,
                  active: pixel.conversion,
                }"
              >
                {{ pixel.conversion ? "Ativo" : "Inativo" }}
              </b-col>
              <!--Envios Web-->
              <b-col
                cols="1"
                :class="{ inactive: !pixel.web, active: pixel.web }"
              >
                {{ pixel.web ? "Ativo" : "Inativo" }}
              </b-col>
              <!-- Api de conversão -->
              <b-col
                cols="1"
                :class="{ inactive: !pixel.api, active: pixel.api }"
              >
                {{ pixel.api ? "Ativo" : "Inativo" }}
              </b-col>
              <!--AÇOES-->
              <b-col
                cols="1"
                class="d-flex"
                style="
                  display: flex !important;
                  justify-content: flex-end !important;
                "
              >
                <b-dropdown id="dropForm" right>
                  <template #button-content>
                    <img
                      src="@/assets/img/icons/dots.svg"
                      alt="dots"
                      class="ml-2"
                    />
                  </template>
                  <b-dropdown-item @click="editPixel(pixel)"
                    >Editar</b-dropdown-item
                  >
                  <b-dropdown-item @click="deletePixel(pixel)"
                    >Deletar</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
            </b-row>
          </template>
        </div>
      </template>

      <div v-else>
        <div v-for="(item, index) in 10" :key="index">
          <div class="d-flex align-items-center justify-content-around my-3">
            <b-skeleton height="75px" width="100%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <!-- SE NÃO EXISTIR PIXELS -->
    <b-row
      v-if="!loading && pixels.length === 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhum pixel criado</p>
    </b-row>

    <b-row>
      <b-col>
        <Paginate
          v-if="pixels.length > 0 && !isMobile"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :disabled="true"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>

    <!-- MODAL -->
    <ModalCreatePixel />
    <ModalEditPixel :pixel="selectedPixel" />
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Paginate from "@/components/Paginate";
//
import PixelService from "@/services/resources/PixelService";
const servicePixel = PixelService.build();
//
import BaseHeader from "@/components/BaseHeader";
//
import ModalCreatePixel from "./PixelCreate.vue";
//
import ModalEditPixel from "./ModalEditPixel.vue";
export default {
  components: { BaseHeader, Paginate, ModalCreatePixel, ModalEditPixel },
  data() {
    return {
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Pixel", to: this.$route.fullPath },
      ],

      selectedPixel: {},

      total: 0,
      pixels: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      loading: false,
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    editPixel(pixel) {
      this.selectedPixel = pixel;
      this.$bvModal.show("modal-edit-pixel");
    },
    deletePixel(pixel) {
      Vue.swal({
        title: "Pixel",
        text: `Deseja realmente deletar o pixel?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          var data = {
            id: pixel.id,
          };
          servicePixel
            .destroy(data)
            .then(() => {
              this.loading = false;
              this.$bvToast.toast("Pixel deletado com sucesso", {
                title: "Pixel",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getPixels();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar pixel", {
                title: "Pixel",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.getPixels();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.getPixels();
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
    }, 500),
    getPixels(search = null) {
      this.loading = true;

      this.pagination.totalPages = 1;
      let data = {
        page: this.pagination.currentPage,
      };

      servicePixel
        .search(data)
        .then((resp) => {
          this.pixels = resp.data;
          this.total = resp.total;
          this.loading = false;
          this.pagination.totalPages = resp.last_page;
          this.pagination.currentPage = resp.current_page;
          this.pagination.perPage = resp.per_page;
        })
        .catch((error) => {
          // console.log("error", error);
        });
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.getPixels();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1500px !important;
}
.inactive {
  color: #d31408;
  font-size: 14px;
  font-weight: 500;
  background-color: #d3140833;
  padding: 2px 4px;
  border-radius: 5px;
  text-align: center;
  margin: 0 2px;
}
.active {
  color: var(--greenn);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--greenn-transparent);
  padding: 2px 4px;
  border-radius: 5px;
  text-align: center;
  margin: 0 2px;
}

.Table-body-container .Table-body {
  padding: 5px !important;
}
.Table-body-container:nth-child(2n-2) .Table-body {
  background-color: #ffffff !important;
}
.Table-header {
  padding: 0px 0px 20px 0px !important;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 0 0;
  padding-top: 15px;
}
@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }
  .Table-body {
    gap: 50px;
  }
  .header-table {
    display: block;
    padding: 0;
    position: relative;
  }
  .container-pesquisa {
    text-align: left;
    padding-bottom: 10px;
  }
}
</style>
